<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-progress-linear
                  :active="getAuthLoading"
                  :indeterminate="getAuthLoading"
                  absolute
                  top
                  color="primary"
                ></v-progress-linear>
                <v-form
                  ref="form"
                  v-model="validForm"
                  v-on:submit.prevent="formSubmit">
                  <v-card-text class="text-center">
                    <v-avatar size="60" class="mb-4">
                        <img src="@/assets/images/logo.svg" alt="" />
                    </v-avatar>
                    <p v-if="!success" class="text--disabled font-weight-medium mb-10">
                      Reset password link will be sent to your email address
                    </p>
                    <v-alert v-if="alertMessage" :color="alertColor + ' lighten-1'" dark>
                      {{ alertMessage }}
                    </v-alert>
                    <template v-if="!success">
                      <v-text-field label="Email" v-model="email" :rules="emailRules"></v-text-field>
                      <v-btn dark class="mb-4" block color="primary"
                        type="submit" :disabled="getAuthLoading">Submit</v-btn>
                    </template>
                    <div class="d-flex justify-around">
                      <v-btn text small color="primary" :disabled="getAuthLoading"
                        to="/auth/sign-in">
                        Sign In
                      </v-btn>
                    </div>
                </v-card-text>
                </v-form>
            </base-card>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'forgot-password',
  metaInfo: {
    title: 'Forgot Password'
  },
  data() {
    return {
      validForm: false,
      show: false,
      email: null,
      success: false,
      alertMessage: null,
      alertColor: null,
      emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  computed: {
    ...mapGetters(['getAuthLoading', 'getAuthError']),
  },
  methods: {
      ...mapActions(['forgotPassword']),
      async formSubmit() {
        this.alertMessage = null;
        this.alertColor = null;
        if (!this.$refs.form.validate()) {
          return;
        }

        const response = await this.forgotPassword({ email: this.email });
        if (_.get(response, 'data')) {
          this.success = true;
          this.alertMessage = response.data;
          this.alertColor = 'green';
        }
      },
      showSnackbar(message, duration = 2000) {
        this.snackbar = true;
        this.snackbarMessage = message;
        this.snackbarTimeout = duration;
      }
  },
  watch: {
    getAuthError(errorMessage) {
      this.alertMessage = errorMessage;
      this.alertColor = 'red';
    }
  }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
